<template>
<!-- 2022/12/12应对821车辆调度的车辆管理 -->
  <div class="vehicle-management" ref="userManagement">
    <div class="function-buttons" ref="functionButtons">
      <el-button
        type="primary"
        size="small"
        @click="onAddVehicle"
        v-if="$store.state.menu.nowMenuList.indexOf('新增') >= 0"
      >新增</el-button>
      <el-button
        type="primary"
        size="small"
        @click="importExcel"
        v-if="$store.state.menu.nowMenuList.indexOf('导入') >= 0"
      >导入</el-button>
    </div>
    <div class="form-area" ref="form">
      <el-form
        :inline="true"
        :model="form"
        class="demo-form-inline"
        label-position="left"
        label-width="96px"
      >
        <el-form-item label="车牌号：">
          <el-input v-model.trim="form.cph" placeholder="请输入车牌号"></el-input>
        </el-form-item>
        <el-form-item label="车架号：">
          <el-input v-model.trim="form.vehicleNo" placeholder="请输入车架号"></el-input>
        </el-form-item>
        <el-form-item label="所属企业：">
          <companySelect
            ref="companySelect"
            :clearable="true"
            :companyTree="companyList"
            :valueName="valueName"
            :value="value"
            @getValue="getGroupId"
          ></companySelect>
        </el-form-item>
        <el-form-item label="车辆类别：">
          <el-select v-model="form.vehicleClass" clearable placeholder="请选择车辆类别">
            <el-option
              v-for="item in vehicleClassList"
              :label="item.dictValue"
              :value="item.dictCode"
              :key="item.dictCode"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="车辆状态：">
          <el-select v-model="form.vehicleStatus" clearable placeholder="请选择车辆状态">
            <el-option
              v-for="item in vehicleStatusList"
              :label="item.dictValue"
              :value="item.dictCode"
              :key="item.dictCode"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="onSearch">查询</el-button>
          <el-button
            type="primary"
            size="small"
            :disabled="tableData.length === 0"
            :loading="downLoadStatus"
            @click="exportData"
          >导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="tableData" stripe :height="tableHeight" :row-class-name="rowColor">
      <el-table-column type="index" label="序号" width="60"></el-table-column>
      <el-table-column prop="cph" label="车牌号"></el-table-column>
      <el-table-column prop="companyName" label="所属企业" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="type" label="车辆类别" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{
          vehicleClassListObj[Number(scope.row.vehicleClass)]
          }}
        </template>
      </el-table-column>
      
      <el-table-column prop="vehicleNo" label="车架号" :show-overflow-tooltip="true"></el-table-column>
      
      <el-table-column prop="vehicleStatus" label="车辆状态" width="80">
        <template slot-scope="scope">
          {{
          vehicleStatusObj[Number(scope.row.vehicleStatus)]
          }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150px">
        <template slot-scope="scope">
          <el-button
            @click="changeVehicleStatus(scope.row)"
            type="text"
            size="small"
            style="color: #007aff"
            v-if="$store.state.menu.nowMenuList.indexOf('编辑') >= 0"
            >更改车辆状态</el-button
          >
          <!-- <div style="display:flex;"> -->
            
            
            <el-button
              @click="onUpdateVehicle(scope.row)"
              type="text"
              size="small"
              sort="primary"
              v-if="$store.state.menu.nowMenuList.indexOf('编辑') >= 0"
            >编辑</el-button>
          <!-- </div> -->
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination" ref="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
    <!-- 新增编辑弹窗 -->
    <el-dialog
      :title="title"
      v-if="dialogVisible"
      :visible.sync="dialogVisible"
      width="70%"
      custom-class="add-vehicle-dialog"
    >
      <addVehicle
        ref="addVehicle"
        :vehicleNo="vehicleNo"
        :rowData="rowData"
        :disabled="true"
        @saveEnd="saveEnd"
        @onLoading="onLoading"
      />

      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" size="small" :loading="editLoading" @click="onSave">保存</el-button>
      </span>
    </el-dialog>
    <!-- 导入 -->
    <el-dialog title="导入" v-if="downVisible" :visible.sync="downVisible" width="30%">
      <upload-file
        @uploadSuccess="uploadSuccess"
        url="base/vehicle/importDriver"
        class="import"
        :type="0"
      ></upload-file>
    </el-dialog>
    <!-- 修改车辆状态 -->
    <el-dialog title="修改车辆状态" width="300px" :visible.sync="statusVisible">
      <el-form label-width="70px">
        <!-- <el-form-item label="车牌号:">
          {{ changeStatusTarget.cph }}
        </el-form-item>-->
        <el-form-item label="车辆状态：">
          <el-select v-model="changeStatus">
            <el-option
              v-for="item in vehicleStatusList"
              :key="item.dictCode"
              :value="item.dictCode"
              :label="item.dictValue"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label-width="0">
          <el-button size="small" @click="statusVisible = false">取消</el-button>
          <el-button size="small" :disabled="!changeStatus" type="primary" @click="submitChange">确认</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 修改车辆状态-工单审批 -->
    <order-helper ref="orderHelper" v-if="orderHelperSataus" :rowData="changeStatusTarget" @closeDia="(v)=>this.orderHelperSataus=v" />
  </div>
</template>

<script>
import {
  getVehicleList,
  queryDictsByCodes,
  getBusinessCompanyTree,
  updateVehicleStatus,
} from "@/api/lib/api.js";
import { formatDict, getCurent, formatDate } from "@/common/utils/index";
import uploadFile from "@/components/uploadFile/uploadFile.vue";
import companySelect from "@/components/treeSelect/companySelect.vue";
import addVehicle from "./components/addVehicle.vue";
import orderHelper from "@/views/layout/components/orderHelper.vue"

import { addExcelExport } from "@/api/lib/refreshAlarm.js";

export default {
  name: "vehicleManagement",
  components: {
    uploadFile,
    companySelect,
    addVehicle,
    orderHelper
  },
  data () {
    return {
      tableHeight: 0,
      tableData: [],
      value: null,
      valueName: null,
      valueNameAdd: null,
      valueAdd: null,
      form: {
        cph: "",
        vehicleNo: "",
        companyName: [],
        vehicleClass: "",
        vehicleStatus: null,
        currentPage: 1,
        pageSize: 10,
      },
      vehicleClassList:[
        {
          dictCode:'18',
          dictValue:'巡回车'
        },
        {
          dictCode:'19',
          dictValue:'公务车'
        },
        {
          dictCode:'20',
          dictValue:'通勤车'
        },
      ],
      vehicleStatusList: [],
      typeObj: {},
      vehicleStatusObj: {},
      powerTypeObj: {},
      modelObj: {},
      deviceTypeObj: {},
      total: 0,
      dialogVisible: false,
      title: "",
      companyList: [],
      vehicleNo: "",
      rowData: null,
      editLoading: false,
      exportForm: {},
      downLoadStatus: false,
      downVisible: false,
      statusVisible: false,
      changeStatus: "",
      changeStatusTarget: {},
      orderHelperSataus: false,
    };
  },

  methods: {
    //指定行修改文字颜色
    rowColor ({ row, rowIndex }) {
      if (row.expired === 1) {
        return 'redColor'
      }
      return ''
    },
    // 修改车辆状态
    changeVehicleStatus (row) {
      // this.statusVisible = true;
      this.orderHelperSataus = true;
      this.changeStatusTarget = row;
      this.changeStatus = row.vehicleStatus;
      this.$nextTick(()=>{
        this.$refs.orderHelper.showForm();
      })
    },
    submitChange () {
      updateVehicleStatus({
        id: this.changeStatusTarget.id,
        status: this.changeStatus,
      }).then((res) => {
        if (res.code === 1000) {
          this.$message.success("修改成功!");
          this.statusVisible = false;
          this.getTableData();
        }
      });
    },
    // 计算表格高度
    computeHeight () {
      const wholeHeight = this.$refs.userManagement.clientHeight;
      const buttonsHeight = this.$refs.functionButtons.clientHeight;
      const formHeight = this.$refs.form.clientHeight;
      const paginationHeight = this.$refs.pagination.clientHeight;
      this.tableHeight =
        wholeHeight -
        16 -
        buttonsHeight -
        16 -
        formHeight -
        paginationHeight -
        10 -
        16;
    },
    //导出
    exportData () {
      this.downLoadStatus = true;
      delete this.exportForm.currentPage;
      delete this.exportForm.pageSize;
      let data = {
        baseUrl: "base",
        userId:
          sessionStorage.getItem("userId") || localStorage.getItem("userId"),
        createTime: formatDate(new Date()),
        fileName: `车辆信息-${getCurent()}`,
        filePath: null,
        fileStatus: 1,
        generateTime: null,
        queryParam: JSON.stringify(this.exportForm),
        queryPath: "/vehicle/vehicleExport",
      };
      addExcelExport(data)
        .then((res) => {
          this.$message.success(res.msg);
          this.downLoadStatus = false;
          this.$store.dispatch("setRefreshStatus", true);
        })
        .catch(() => {
          this.downLoadStatus = false;
        });
    },
    onSearch () {
      this.form.currentPage = 1;
      this.getTableData();
    },
    // 获取表格数据
    getTableData () {
      getVehicleList(this.form).then((res) => {
        if (res.code === 1000) {
          this.tableData = res.data.list;
          this.total = res.data.total;
        } else {
          this.tableData = [];
          this.total = 0;
          this.$message.error(res.msg);
        }
        this.exportForm = { ...this.form };
      });
    },
    // 当前页码改变
    onCurrentChange (page) {
      this.form.currentPage = page;
      this.getTableData();
    },
    // 当前条数改变
    onSizeChange (size) {
      this.form.pageSize = size;
      this.form.currentPage = 1;
      this.getTableData();
    },
    // 下载模板
    onExport () {
      this.downLoadStatus = true;
      let data = {
        baseUrl: "base",
        fileName: `车辆导入模板-${getCurent()}`,
        queryParam: "",
        queryPath: "/vehicle/importVehicleTemplateExport",
      };
      addExcelExport(data)
        .then((res) => {
          this.$message.success(res.msg);
          this.downLoadStatus = false;
          this.$store.dispatch("setRefreshStatus", true);
        })
        .catch(() => {
          this.downLoadStatus = false;
        });
    },
    // 点击表格查看按钮
    onUpdateVehicle (row) {
      this.title = "修改车辆信息";
      this.rowData = { ...row };
      this.vehicleNo = row.vehicleNo;

      this.dialogVisible = true;
    },
    // 新增车辆
    onAddVehicle () {
      this.title = "新增车辆";
      this.rowData = null;
      this.vehicleNo = "";
      this.dialogVisible = true;
    },

    // 获取企业树
    getCompanyList () {
      getBusinessCompanyTree().then((res) => {
        if (res.code === 1000) {
          this.companyList = res.data;
        }
      });
    },
    getGroupId (v) {
      this.form.companyId = v;
    },
    onSave () {
      this.$refs.addVehicle.onSave();
    },
    saveEnd () {
      this.dialogVisible = false;
      this.getTableData();
    },
    onLoading (flag) {
      this.editLoading = flag;
    },
    //导入
    importExcel () {
      this.downVisible = true;
    },
    //导入成功
    uploadSuccess () {
      this.downVisible = false;
      this.onSearch();
    },
    //获取字典值
    getDicts () {
      queryDictsByCodes({ parentCodes: "VETY,CLZT,DLLX,ZDLX,CX" }).then(
        (res) => {
          if (res.code === 1000) {
            this.typeList = res.data.VETY;
            this.vehicleStatusList = res.data.CLZT;
            this.powerTypeObj = formatDict(res.data.DLLX);
            this.modelObj = formatDict(res.data.CX);
            this.deviceTypeObj = formatDict(res.data.ZDLX);
            this.typeObj = formatDict(this.typeList);
            this.vehicleStatusObj = formatDict(this.vehicleStatusList);
          }
        }
      );
    },
  },
  created () {
    this.getCompanyList();
    this.getDicts();
    this.vehicleClassListObj = formatDict(this.vehicleClassList);
  },
  mounted () {
    this.$nextTick(() => {
      this.computeHeight();
      this.onSearch();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
  destroyed () {
    window.removeEventListener("resize", this.computeHeight);
  },
};
</script>

<style lang="scss" scoped>
.vehicle-management {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;
}

.add {
  height: 100%;
  padding: 2vh 3vh;
}
// .theme-project-resourse {
.vehicle-management {
  background: #ffffff;
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
}
// }
/deep/.el-table .redColor td {
  color: #ff1212;
}
</style>
